import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  MenuFilesDisplay,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";
import "../style/catering.css";

export default class CateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      breakfastMenu,
      lunchMenu,
      coldLunches,
      dinner,
      dinnerBuffet,
      beverageService,
      horsdoeuvres,
      wedding,
      nonprofitBuffet,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Catering"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[
                fishermanBusiness.ctas.address,
                fishermanBusiness.ctas.phone,
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container catering-page-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <MenuFilesDisplay
                files={[
                  { ...breakfastMenu, url: breakfastMenu.file },
                  { ...lunchMenu, url: lunchMenu.file },
                  { ...coldLunches, url: coldLunches.file },
                  { ...dinner, url: dinner.file },
                  { ...dinnerBuffet, url: dinnerBuffet.file },
                  { ...beverageService, url: beverageService.file },
                  { ...horsdoeuvres, url: horsdoeuvres.file },
                  { ...wedding, url: wedding.file },
                  { ...nonprofitBuffet, url: nonprofitBuffet.file },
                ]}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        address {
          text
          link
          internal
        }
        phone {
          text
          link
          internal
        }
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Catering" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              fluid(maxWidth: 720) {
                aspectRatio
                base64
                src
                srcSet
              }
            }
          }
        }
      }
    }
    breakfastMenu: fishermanBusinessFile(filename: { eq: "Breakfast" }) {
      filename
      file
    }
    lunchMenu: fishermanBusinessFile(filename: { eq: "Lunch" }) {
      filename
      file
    }
    coldLunches: fishermanBusinessFile(filename: { eq: "Cold Lunches" }) {
      filename
      file
    }
    dinner: fishermanBusinessFile(filename: { eq: "Dinner" }) {
      filename
      file
    }
    dinnerBuffet: fishermanBusinessFile(filename: { eq: "Dinner Buffet" }) {
      filename
      file
    }
    beverageService: fishermanBusinessFile(
      filename: { eq: "Beverage Service" }
    ) {
      filename
      file
    }
    horsdoeuvres: fishermanBusinessFile(filename: { eq: "Hors D'oeuvres" }) {
      filename
      file
    }
    wedding: fishermanBusinessFile(filename: { eq: "Wedding" }) {
      filename
      file
    }
    nonprofitBuffet: fishermanBusinessFile(
      filename: { eq: "Non-Profit Buffet" }
    ) {
      filename
      file
    }
  }
`;
